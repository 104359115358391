import { useState, useCallback } from 'react';
import qs from 'query-string';
import debounce from 'debounce';

const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export default (key, initialValue) => {
  const setQueryStringValueDebounced = debounce(setQueryStringValue, 1000);
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValueDebounced(key, newValue);
    },
    [key, setQueryStringValueDebounced]
  );

  return [value, onSetValue];
};

const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue;
  window.history.replaceState({ path: newurl }, '', newurl);
};

const getQueryStringValue = (key, queryString) => {
  if (!queryString) {
    if (typeof window !== 'undefined') {
      queryString = window.location.search;
    }
  }

  const values = qs.parse(queryString);
  return values[key];
};
