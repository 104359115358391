import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper
} from 'common/src/containers/Saas/saas.style';
import GraphQLErrorList from '../components/graphqlerrorlist';
import SEO from '../components/seo';
import { toPlainText } from '../lib/helpers';

import { useCookie } from '@use-hook/use-cookie';

import Heading from 'reusecore/src/elements/Heading';

import Navbar from 'common/src/containers/Saas/Navbar';
import Footer from 'common/src/containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Box from 'reusecore/src/elements/Box';
import Card from 'reusecore/src/elements/Card';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import {
  getSolution,
  calcTransactionFees,
  getPOSType
} from 'common/src/data/Saas/';

import { Icon } from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';

import { saasTheme } from 'common/src/theme/saas';

import Button from 'reusecore/src/elements/Button';

import PortableText from '../components/portableText';

import useQueryString from '../lib/useQueryString';

export const pageQuery = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query OfferTemplateQuery($id: String!) {
    offer: sanityOffer(id: { eq: $id }) {
      id
      publishedAt
      offerName
      offerID
      online
      offerPage {
        href
        blank
      }
      slug {
        current
      }
      bank {
        name
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const OfferTemplate = props => {
  const { data, errors, row, col } = props;
  const offer = data && data.offer;

  const staticOffer = getSolution(offer.offerID);

  const [phoneCookie] = useCookie('phone');
  const [phone, setPhone] = useState(phoneCookie);

  const [axeptaHashCookie] = useCookie('axepta_hash');
  const [axeptaHash, setHash] = useState(axeptaHashCookie);

  const [emailCookie] = useCookie('email');
  const [email, setEmail] = useState(emailCookie);

  const [selectedOfferUUIDCookie] = useCookie('selectedOfferUUID');
  const [selectedOfferUUIDQ, setSelectedOfferUUIDQ] = useQueryString('id');
  const [overrideOfferButton] = useQueryString('button');
  const selectedOfferUUID = useMemo(
    () => selectedOfferUUIDQ || selectedOfferUUIDCookie,
    [selectedOfferUUIDQ, selectedOfferUUIDCookie]
  );

  const [amountCookie] = useCookie('amount');
  const [amount, setAmount] = useState(amountCookie);
  const [isLoading, setIsLoading] = useState(!!selectedOfferUUID);

  const [isClient, setIsClient] = useState(false);

  const costs = useMemo(
    () =>
      calcTransactionFees({
        amount: amount / 12,
        solutionId: offer.offerID
      }),
    [amount, offer.offerID]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
    }

    axios
      .get(
        `${process.env.GATSBY_BACKEND_URL}/hooks/offer?uuid=${selectedOfferUUID}`
      )
      .then(response => {
        const { amount, phone_number, axepta_hash, email } = response.data;
        if (!amount) return;

        setAmount(amount);
        setPhone(phone_number);
        setHash(axepta_hash);
        setEmail(email);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setAmount(false);
        setSelectedOfferUUIDQ();
        console.log(err);
      });
  }, [selectedOfferUUID, setSelectedOfferUUIDQ]);

  return (
    <ThemeProvider theme={saasTheme}>
      {errors && <SEO title="GraphQL Error" />}
      {offer && (
        <SEO
          title={
            `Offerta POS ${offer.offerName} di ${offer.bank.name}` || 'Untitled'
          }
          description={
            toPlainText(offer._rawExcerpt) ||
            `${offer.bank.name} ${offer.offerName}${
              staticOffer.deviceName ? ` con ${staticOffer.deviceName}` : ''
            }: commissioni, prezzi, canone, condizioni e istruzioni su come sottoscrivere l'offerta.`
          }
          image={offer.mainImage}
        />
      )}
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar button={!selectedOfferUUID && !overrideOfferButton} />
          </DrawerProvider>
        </Sticky>
        <Wrapper>
          <Container>
            {errors && <GraphQLErrorList errors={errors} />}
            {isClient && phone && !offer.online && (
              <Box {...row}>
                <Box {...col}>
                  <Card
                    borderColor="#ddd"
                    border={'1px solid'}
                    borderRadius="5px"
                    boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.05)'}
                    pt={20}
                    pl={30}
                    pr={30}
                    pb={30}
                    style={{ position: 'relative' }}
                  >
                    <Text content="Grazie," fontWeight="700" fontSize="18px" />
                    <Text
                      htmlContent={`Abbiamo ricevuto la tua richiesta di ricontatto.<br>Verrai ricontattato al numero: <b style="font-size: 18px;">${phone}</b>`}
                      mb="1.75rem"
                      fontSize="18px"
                      lineHeight="1.5rem"
                    />
                    <List>
                      <ListItem>
                        <Icon
                          icon={ic_check_circle}
                          size="14"
                          style={{
                            display: 'flex',
                            color: '#00a86b',
                            margin: '0 10px 0 5px'
                          }}
                        />
                        La consulenza è gratis e senza impegno
                      </ListItem>
                      <ListItem>
                        <Icon
                          icon={ic_check_circle}
                          size="14"
                          style={{
                            display: 'flex',
                            color: '#00a86b',
                            margin: '0 10px 0 5px'
                          }}
                        />
                        Fai pure tutte le domande necessarie per chiarirti ogni
                        dubbio
                      </ListItem>
                      <ListItem>
                        <Icon
                          icon={ic_check_circle}
                          size="14"
                          style={{
                            display: 'flex',
                            color: '#00a86b',
                            margin: '0 10px 0 5px'
                          }}
                        />
                        Potrai procedere all'attivazione dell'offerta
                      </ListItem>
                    </List>

                    <ButtonWrapper>
                      {false && (
                        <Button
                          onClick={() => alert('nobody is on the phone')}
                          colors="primary"
                          type="button"
                          title="Scegli l'orario della chiamata"
                          fontSize={['13px', '14px']}
                          width={['100%', '100%', '100%', 250, 250]}
                        />
                      )}
                      {false && (
                        <Text
                          content="Ti richiameremo appena possibile."
                          color="#333"
                          fontSize="12px"
                          mt="10px"
                          textAlign="center"
                          mb="0"
                        />
                      )}
                    </ButtonWrapper>
                  </Card>
                </Box>
              </Box>
            )}
            <Text
              {...{
                as: 'span',
                display: 'block',
                fontSize: '14px',
                letterSpacing: '0.15em',
                fontWeight: '700',
                color: 'primary',
                mb: '20px'
              }}
              content={
                phone
                  ? "L'offerta che hai scelto"
                  : "L'offerta che ti interessa"
              }
              style={{ textTransform: 'uppercase' }}
            />
            <Box {...row} mb="20px">
              <Box
                {...col}
                display={['block', 'block', 'flex', 'flex', 'flex']}
              >
                <Box
                  flexBox
                  mb={[20, 20, 0, 0, 0]}
                  mr={[0, 0, 20, 20, 20]}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={staticOffer.vendorLogo}
                    height={staticOffer.logoHeight || '50px'}
                    alt={`Logo di ${offer.offerName} di ${offer.bank.name}`}
                    width="auto"
                  />
                </Box>
                <Box
                  mb={[20, 20, 0, 0, 0]}
                  mr={[0, 0, 20, 20, 20]}
                  style={{ flexGrow: '1' }}
                >
                  <Heading
                    as="h2"
                    content={`${offer.offerName} di ${offer.bank.name}`}
                    color="headingColor"
                  ></Heading>
                  {amount && !isLoading && (
                    <div>
                      Con un transato annuo di{' '}
                      <b>{parseInt(amount).toLocaleString()}€</b>, stimiamo che
                      il costo totale da te sostenuto comprensivo di tutte le
                      commissioni e il canone sarà di{' '}
                      <b>{Math.round(costs.firstYear).toLocaleString()}€</b> per
                      il primo anno e{' '}
                      {Math.round(costs.nextYears).toLocaleString()}€ per gli
                      anni successivi. Si tratta di un costo di circa <b>{Math.round(costs.firstYear / 12).toLocaleString()}€</b> al mese.
                    </div>
                  )}
                  {!selectedOfferUUID && !isLoading && overrideOfferButton && (
                    <div>
                      Qui sotto puoi trovare tutte le informazioni che ti
                      servono per convenzionarti in autonomia. Non esistare a
                      contattarci nel caso avessi dei dubbi!
                    </div>
                  )}
                  {isLoading && <div>Calcolo...</div>}
                </Box>
                <Box>
                  {offer.online &&
                    (phone || overrideOfferButton) &&
                    (staticOffer.formInOfferPage ? (
                      <form
                        action={
                          (offer.offerPage && offer.offerPage.href) ||
                          staticOffer.pageUrl
                        }
                        method="POST"
                      >
                        <input
                          type="hidden"
                          name="id_signor_pos"
                          value={selectedOfferUUID}
                        />
                        <input type="hidden" name="email" value={email} />
                        <input type="hidden" name="telefono" value={phone} />
                        <input type="hidden" name="hash" value={axeptaHash} />
                        <Button
                          type="submit"
                          colors="primaryWithBg"
                          title="Attiva l'offerta online"
                          fontSize={['13px', '14px']}
                          pulse
                          width={['100%', '100%', '100%', 250, 250]}
                        />
                      </form>
                    ) : (
                      <Button
                        external
                        target="_blank"
                        href={
                          (offer.offerPage && offer.offerPage.href) ||
                          staticOffer.pageUrl
                        }
                        onClick={e => {
                          axios.post(
                            `${process.env.GATSBY_BACKEND_URL}/hooks/offer/button`,
                            {
                              selectedOfferUUID
                            }
                          );
                        }}
                        colors="primaryWithBg"
                        title="Attiva l'offerta online"
                        fontSize={['13px', '14px']}
                        pulse
                        width={['100%', '100%', '100%', 250, 250]}
                      />
                    ))}
                  {offer.online && (phone || overrideOfferButton) && (
                    <div style={{ marginBottom: '10px' }}>
                      <StyledAnchorLink href="#instructions" offset={120}>
                        Hai bisogno di istruzioni? <b>Clicca qui</b>
                      </StyledAnchorLink>
                    </div>
                  )}
                  {false && (
                    <Button
                      onClick={() => alert('nobody is on the phone')}
                      colors="primary"
                      type="button"
                      title="Richiedi assistenza"
                      fontSize={['13px', '14px']}
                      width={['100%', '100%', '100%', 250, 250]}
                    />
                  )}
                  {false && (
                    <Text
                      content="Ti richiameremo appena possibile."
                      color="#333"
                      fontSize="12px"
                      mt="10px"
                      textAlign="center"
                      mb="0"
                    />
                  )}
                </Box>
              </Box>
              <Box {...col}>
                <Heading
                  as="h3"
                  content={`Riepilogo offerta`}
                  color="headingColor"
                  mb="5px"
                />
                <SpecTable>
                  {staticOffer.oneTimeFees &&
                    staticOffer.oneTimeFees.length > 0 &&
                    staticOffer.oneTimeFees.map(({ label, value }) => (
                      <>
                        <dt>{label}</dt>
                        <dd>{value}€</dd>
                      </>
                    ))}
                  {staticOffer.monthlyFees &&
                    staticOffer.monthlyFees.length > 0 &&
                    staticOffer.monthlyFees.map(({ label, value }) => (
                      <>
                        <dt>{label}</dt>
                        <dd>{value}€</dd>
                      </>
                    ))}
                  {staticOffer.specialFees &&
                    staticOffer.specialFees.length > 0 &&
                    staticOffer.specialFees.map(
                      ({ label, value, valueLabel }) => (
                        <>
                          <dt>{label}</dt>
                          <dd
                            dangerouslySetInnerHTML={{
                              __html: valueLabel
                                ? valueLabel
                                : `${value.toLocaleString()}€`
                            }}
                          ></dd>
                        </>
                      )
                    )}
                  {!!staticOffer.firstYearFixedCost && (
                    <dt>Costo di installazione/spedizione POS</dt>
                  )}
                  {!!staticOffer.firstYearFixedCost && (
                    <dd>{staticOffer.firstYearFixedCost}€</dd>
                  )}
                  <dt>Commissioni carte consumer</dt>
                  {staticOffer.variableFeesLabels &&
                    staticOffer.variableFeesLabels.visa &&
                    staticOffer.variableFeesLabels.visa.credit && (
                      <dd>{staticOffer.variableFeesLabels.visa.credit}</dd>
                    )}
                  {!staticOffer.variableFeesLabels && (
                    <dd>
                      {typeof staticOffer.variableFees === 'function'
                        ? staticOffer.variableFees(amount)
                        : staticOffer.variableFees &&
                          parseFloat(
                            (
                              staticOffer.variableFees.visa.credit * 100
                            ).toFixed(2)
                          ).toLocaleString()}
                      %
                    </dd>
                  )}
                  <dt>Commissioni carte commercial</dt>
                  {staticOffer.variableFeesLabels &&
                    staticOffer.variableFeesLabels.visa &&
                    staticOffer.variableFeesLabels.visa.commercial && (
                      <dd>{staticOffer.variableFeesLabels.visa.commercial}</dd>
                    )}
                  {!staticOffer.variableFeesLabels && (
                    <dd>
                      {typeof staticOffer.variableFees === 'function'
                        ? staticOffer.variableFees(amount)
                        : staticOffer.variableFees &&
                          parseFloat(
                            (
                              staticOffer.variableFees.visa.commercial * 100
                            ).toFixed(2)
                          ).toLocaleString()}
                      %
                    </dd>
                  )}
                  <dt>Commissioni PAGOBancomat</dt>
                  {staticOffer.variableFeesLabels &&
                    staticOffer.variableFeesLabels.pagoBancomat && (
                      <dd>{staticOffer.variableFeesLabels.pagoBancomat}</dd>
                    )}
                  {!staticOffer.variableFeesLabels && (
                    <dd>
                      {typeof staticOffer.variableFees === 'function'
                        ? staticOffer.variableFees(amount)
                        : staticOffer.variableFees &&
                          parseFloat(
                            (
                              staticOffer.variableFees.pagoBancomat * 100
                            ).toFixed(2)
                          ).toLocaleString()}
                      %
                    </dd>
                  )}
                  <dt>Tipologia POS</dt>
                  <dd>
                    {staticOffer.posTypesAlternativeLabels &&
                    staticOffer.posTypesAlternativeLabels.length > 0
                      ? staticOffer.posTypesAlternativeLabels.join(' e ')
                      : staticOffer.posTypes
                          .map(id => getPOSType(id))
                          .join(', ')}
                  </dd>
                  {false && <dt>Costo in caso di disdetta</dt>}
                  {false && <dd></dd>}
                </SpecTable>
                {staticOffer.tableAdditionalInfo && (
                  <p
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    {staticOffer.tableAdditionalInfo}
                  </p>
                )}
              </Box>
            </Box>
            <Box>
              {offer.online && (
                <Instructions id="instructions">
                  <Heading
                    as="h2"
                    content={`Come attivare l'offerta ${offer.offerName} di ${offer.bank.name} online`}
                    color="headingColor"
                  ></Heading>
                  {offer._rawBody && <PortableText blocks={offer._rawBody} />}
                </Instructions>
              )}
            </Box>
          </Container>
        </Wrapper>
        {offer.online && (selectedOfferUUID || overrideOfferButton) && (
          <CtaSection>
            <Heading
              as="h3"
              content={`Sei a un passo dalla convenienza di ${offer.offerName} di ${offer.bank.name}`}
              color="headingColor"
            ></Heading>
            <Text content="Puoi attivare l'offerta in totale autonomia. Clicca sul bottone." />
            {offer.online &&
              (selectedOfferUUID || overrideOfferButton) &&
              (staticOffer.formInOfferPage ? (
                <form
                  action={
                    (offer.offerPage && offer.offerPage.href) ||
                    staticOffer.pageUrl
                  }
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="id_signor_pos"
                    value={selectedOfferUUID}
                  />
                  <input type="hidden" name="email" value={email} />
                  <input type="hidden" name="telefono" value={phone} />
                  <input type="hidden" name="hash" value={axeptaHash} />
                  <Button
                    type="submit"
                    colors="primaryWithBg"
                    title="Attiva l'offerta online"
                    fontSize={['13px', '14px']}
                    pulse
                    width={['100%', '100%', '100%', 250, 250]}
                  />
                </form>
              ) : (
                <Button
                  external
                  target="_blank"
                  href={
                    (offer.offerPage && offer.offerPage.href) ||
                    staticOffer.pageUrl
                  }
                  onClick={e => {
                    axios.post(
                      `${process.env.GATSBY_BACKEND_URL}/hooks/offer/button`,
                      {
                        selectedOfferUUID
                      }
                    );
                  }}
                  colors="primaryWithBg"
                  title="Attiva l'offerta online"
                  fontSize={['13px', '14px']}
                  pulse
                  width={['100%', '100%', '100%', 250, 250]}
                />
              ))}
          </CtaSection>
        )}
        {!selectedOfferUUID &&
          !overrideOfferButton &&
          typeof window !== 'undefined' && (
            <CtaSection>
              <Heading
                as="h3"
                content={`Sei a un passo dalla convenienza di ${offer.offerName} di ${offer.bank.name}`}
                color="headingColor"
              ></Heading>
              <Text content="Quanto puoi risparmiare con questa offerta? Scoprilo subito cliccando sul bottone qui sotto!" />
              <Button
                external
                target="_blank"
                href="/step-2/"
                colors="primaryWithBg"
                title="Voglio conoscere quanto posso risparmiare"
                fontSize={['13px', '14px']}
                pulse
                width={['100%', '100%', '100%', 250, 250]}
              />
            </CtaSection>
          )}
        <Footer />
      </ContentWrapper>
    </ThemeProvider>
  );
};

const Instructions = styled.div`
  figcaption {
    text-align: center;
    padding: 15px;
    font-style: italic;
    color: #555;
    border-top: 1px solid #ccc;
  }
`

const CtaSection = styled.section`
  padding: 2em 1rem 3rem;
  text-align: center;
  background: #f9fbfd;
`;

const Wrapper = styled.section`
  padding: 104px 0 0;
  background: #fff;
`;

OfferTemplate.propTypes = {
  data: PropTypes.object
};

OfferTemplate.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Team member col default style
  col: {
    width: 1,
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

const ButtonWrapper = styled.div`
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  a {
    color: #333;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: #333;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0;
`;

const List = styled.ul``;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const SpecTable = styled.dl`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 550px;
  margin: 0 dt {
    flex-grow: 1;
  }
  dd {
    font-weight: 700;
  }
`;
export default OfferTemplate;
